import React, { Component } from 'react';
import './Home.css';
import { ListGroup, Row, Col, Image,Button,Modal } from 'react-bootstrap';

// import CommonService from './../../commonService/CommonService';
import { Sidebar, Header } from '../';
import InteractionSearch from '../InteractionSearch/InteractionSearch';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import ViewDetails from '../ViewDetails/ViewDetails';
import RoleConstant from "./../../constants/RoleConstant";
import Moment from 'react-moment';
import {ProgressBar} from 'primereact/progressbar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import MessageConstant from './../../constants/MessageConstant';
import * as uuid from 'uuid';
import PopupModels from '../PopupModels/PopupModels';
import moment from 'moment';
import ConfirmationModal from './../PopupModels/ConfirmationModal';

/**
 * A class that can return some html
 */
class Home extends Component {
  /**
   *renders the ui
   *@return {Object} .
    */
  constructor(props) {
    super(props);
    this.state = {
      interactions: [],
      interactionsSort: [],
      interactionDetails: [],
      roleInteractions:[],
      show: false,
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 3,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 3,
      lastEvaluatedKey: null,
      link: {
        errorLink: '',
        scorecardLink: ''
      },
      searchdata: null,
      datasetIds: [],
      tempInteractions:[],
      showDataSync: false,
      extractSuccess: false,
      extractValue: 0,
      downloadRequest: "Processing",
      sId:'',
      downloadUrl:'',
      deleteConfirmation : false,
      sortedField : '',
      sortingOrder : 'sortOne',
      sortingData : [],
      downloadType : false,
    };
    this.setShow = this.setShow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.firstclick = this.firstclick.bind(this);
    this.lastClick = this.lastClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.onSortInteraction = this.onSortInteraction.bind(this);
    this.setLinkActive = this.setLinkActive.bind(this);
    this.setLinkInactive = this.setLinkInactive.bind(this);
    this.updateIntercations = this.updateIntercations.bind(this);
    this.setSearchData = this.setSearchData.bind(this);
    this.setLastEvaluatedKey = this.setLastEvaluatedKey.bind(this);
    this.downloadInteraction = this.downloadInteraction.bind(this);
    this.getdatasetIds = this.getdatasetIds.bind(this);
    this.extractDataFromBioConnect = this.extractDataFromBioConnect.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.getDatasetListForBioconnect = this.getDatasetListForBioconnect.bind(this);
    this.interval = null;
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
    this.handleDeleteConfirmation=this.handleDeleteConfirmation.bind(this);
    this.getFilterOnRole=this.getFilterOnRole.bind(this);
  }

  downloadInteraction() {
    let data = {};
    if (!this.state.searchdata) {
      data = {}
      data.datasetId = this.state.datasetIds;
    }
     else {
      if (this.state.searchdata.interactionId !== '') {
        data.caseNumber = this.state.searchdata.interactionId;
      }
    // if(this.state.searchdata.selected.length === 0) {
    //   data.datasetId = this.state.datasetIds;
    // }
    if (this.state.searchdata.subcaseNumber !== '') {
      data.subcaseNumber = this.state.searchdata.subcaseNumber;
    }
    if (this.state.searchdata.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.searchdata.associatedTeam;
    }
    if (this.state.searchdata.interactionOwner !== '') {
      data.interactionOwner = this.state.searchdata.interactionOwner;
    }
    if (this.state.searchdata.selected.length !== 0) {
      data.datasetId = this.state.searchdata.selected;
    }
    if (this.state.searchdata.country.length !== 0) {
      data.interactionCountry = this.state.searchdata.country;
    }
    if (this.state.searchdata.channel.length !== 0) {
      data.channel = this.state.searchdata.channel;
    }
    if (this.state.searchdata.status.length !== 0) {
      data.status = this.state.searchdata.status;
    }
    if (this.state.searchdata.assignedTo.length !== 0) {
      data.assignedTo = this.state.searchdata.assignedTo;
    }
  }
  this.setState({downloadType : true});
  data.sortingData = this.state.sortingData;
  data.sId = uuid.v4();
  this.setState({sId:data.sId,downloadRequest:"Processing"});
  //console.log("downinteractions",data);
    utils.makeRequestInBackground(this, EndpointConstant.DOWNLOAD_INTERACTION, data, function (result) {
      //console.log(JSON.stringify(result),"abc");
     // window.open(result.url, 'Download');
    });
  }

  handleDeleteConfirmation() {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation});
  }

  setSearchData(data) {
    //console.log(Object.values(data).length, 'obj length')
    if (data.subcaseNumber === '' && data.associatedTeam.length===0 && data.selected.length===0 &&
      data.country.length===0 && data.channel.length===0 && data.status.length===0 && data.assignedTo.length===0 && data.interactionId === '') {
        this.setState({searchdata: null});
    } else {
      this.setState({searchdata: data});
    }
  }
  handleClick(event) {
    let listid = Number(event.target.innerText);
    let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    //let data=this.state.searchdata
    if (listid === totalPage && this.state.searchdata === null) {
      this.lastClick();
    } else {
      this.setState({
        currentPage: listid
      });
      this.setPrevAndNextBtnClass(listid);
    }

  }

  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
      if (this.state.lastEvaluatedKey !== null) {
        this.setState({ isNextBtnActive: '' });
      }
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
    // else if (listid === totalPage ) {
    //   this.setState({ isNextBtnActive: '' });
    //   // this.lastClick();
    // }
  }
  firstclick() {
    this.setState({ currentPage: 1 });
    this.setState({ upperPageBound: 3 })
    this.setState({ lowerPageBound: 0 })
    this.setPrevAndNextBtnClass(1);
  }
  lastClick() {
    const { lastEvaluatedKey } = this.state;
    let totalpages = Math.ceil(this.state.interactions.length / this.state.todosPerPage)
    this.setState({ currentPage: totalpages });
    this.setState({ upperPageBound: totalpages })
    this.setState({ lowerPageBound: totalpages - 3 });
    this.setPrevAndNextBtnClass(totalpages);
    if (lastEvaluatedKey && this.state.searchdata === null) {
      this.getInteractionList(true);
    } else if (lastEvaluatedKey){
      this.lastClickFilterInteration(true)

    }
  }
  lastClickFilterInteration(paginate) {
    //console.log("searchlastEvaluatedKey",this.state.lastEvaluatedKey);
    //console.log("searchData",this.state.searchdata);
    // let data = {}
    let data = {
      lastEvaluatedKey: paginate === 'back' ? null : this.state.lastEvaluatedKey,

    };
    if (this.state.searchdata.interactionId !== '') {
      data.caseNumber = this.state.searchdata.interactionId;
    }
    if (this.state.searchdata.subcaseNumber !== '') {
      data.subcaseNumber = this.state.searchdata.subcaseNumber;
    }
    // if(this.state.createdBy!==''){
    //   data.createdBy=this.state.createdBy
    // }
    if (this.state.searchdata.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.searchdata.associatedTeam;
    }
    if (this.state.searchdata.interactionOwner !== '') {
      data.interactionOwner = this.state.searchdata.interactionOwner;
    }
    if (this.state.searchdata.selected.length !== 0) {
      data.datasetId = this.state.searchdata.selected;
    }
    if (this.state.searchdata.country.length !== 0) {
      data.interactionCountry = this.state.searchdata.country;
    }
    if (this.state.searchdata.channel.length !== 0) {
      data.channel = this.state.searchdata.channel;
    }
    if (this.state.searchdata.status.length !== 0) {
      data.status = this.state.searchdata.status;
    }
    if (this.state.searchdata.assignedTo.length !== 0) {
      data.assignedTo = this.state.searchdata.assignedTo;
    }

      let self = this;
      utils.makeRequest(this, EndpointConstant.GET_INTERACTION_FROM_JSON, data, function (result) {
        let interactions = result.interactions;
        //console.log("filter data length",interactions);
        if(interactions.length>0) {
        var joined = paginate === 'back' ? interactions : self.state.interactions.concat(interactions);
        self.setState({ interactions: joined});
        // let filteredInteractions=self.state.interactions.concat(interactions)

        if (result.hasOwnProperty('lastEvaluatedKey')) {
          self.setState({ lastEvaluatedKey: result.lastEvaluatedKey });
        }
        else {
          self.setState({ lastEvaluatedKey: null }, function () {
            if (paginate) {
              self.updateLastPage();
            }
          });
        }
      } else {
        self.setState({ lastEvaluatedKey: null }, function () {
          if (paginate) {
            self.updateLastPage();
          }
        });
      }

      });
  }
  updateLastPage() {
    // let totalpages = Math.ceil(this.state.interactions.length / this.state.todosPerPage)
    let listid = this.state.currentPage
    this.setPrevAndNextBtnClass(listid);
  }
  btnIncrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnDecrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - 1 });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);

  }
  btnNextClick() {
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    let listidCall = this.state.currentPage;
    let totalPage = Math.ceil(this.state.interactions.length / this.state.todosPerPage);
    this.setPrevAndNextBtnClass(listid);
    if (listidCall === totalPage) {
      this.lastClick();
    }

  }

  searchResults(searchData) {
    // debugger
    this.setState({
      interactions: searchData, currentPage: 1, upperPageBound: 3,
      lowerPageBound: 0
    }, () => this.setPrevAndNextBtnClass(this.state.currentPage))
  }

  getSortingData(field, order) {
    let newSortingData;
    if (order!== "") {
      newSortingData = [...this.state.sortingData, { sortedField: field, sortingOrder: order }];
    } else {
      newSortingData = [...this.state.sortingData];
    }
    return newSortingData
  }

  onSortInteraction(data, e) {
    e.preventDefault();
    // console.log(e.target.outerHTML);
    // let className = e.target.className;
    let sortId = e.target.id;
    let handleSortingOrder = "";

    if (sortId === 'sort') {
      e.target.id = 'sort-up';
      e.target.className = 'fas fa-sort-up';
      handleSortingOrder = 'sort-up';
    }
    else if (sortId === 'sort-up') {
      e.target.id = 'sort-down';
      e.target.className = 'fas fa-sort-down';
      handleSortingOrder = 'sort-down';
    }
    else if (sortId === 'sort-down') {
      e.target.id = 'sort';
      e.target.className = 'fas fa-sort';
      handleSortingOrder = 'sort';
    }
    const { interactions } = this.state;
    switch (data) {
      case 'caseno':
        let interactionIdSorting = this.getSortingData('Case Number', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('interactionId', { sortId: sortId })), sortingData: interactionIdSorting
        });
        //console.log("Testing interactions", interactionIdSorting);
        break;
      case 'subcaseno':
        let subcaseNumberSorting = this.getSortingData('Sub-Case Number', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('subcaseNumber', { sortId: sortId })), sortingData: subcaseNumberSorting
        })
        //console.log("Testing interactions", subcaseNumberSorting);
        break;
      case 'createdby':
        let createdBySorting = this.getSortingData('Created By', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('createdBy', { sortId: sortId })), sortingData : createdBySorting
        });
        //console.log("Testing interactions", createdBySorting);
        break;
      case 'assocteam':
        let associatedTeamSorting = this.getSortingData('Associated Team', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('associatedTeam', { sortId: sortId })), sortingData : associatedTeamSorting
        });
        //console.log("Testing interactions", associatedTeamSorting)
        //console.log("Testing interactions", this.state.interactions)
        break;
      case 'datasets':
        let datasetNameSorting = this.getSortingData('Dataset Name', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('datasetName', { sortId: sortId })), sortingData : datasetNameSorting
        });
        //console.log("Testing interactions", datasetNameSorting)
        break;
      case 'caseowner':
        let interactionOwnerSorting = this.getSortingData('Owner', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('interactionOwner', { sortId: sortId })), sortingData : interactionOwnerSorting
        });
        //console.log("Testing interactions", interactionOwnerSorting)
        break;
      case 'casectry':
        let interactionCountrySorting = this.getSortingData('Country', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('interactionCountry', { sortId: sortId })), sortingData : interactionCountrySorting
        });
        //console.log("Testing interactions", interactionCountrySorting)
        break;
      case 'channel':
        let channelSorting = this.getSortingData('Channel', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('channel', { sortId: sortId })), sortingData : channelSorting
        });
        //console.log("Testing interactions", channelSorting)
        break;
      case 'modifiedon':
        let interactionModifiedOnSorting = this.getSortingData('Last Modified Date', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('interactionModifiedOn', { sortId: sortId })), sortingData : interactionModifiedOnSorting
        });
        //console.log("Testing interactions", interactionModifiedOnSorting)
        break;
      case 'status':
        let statusSorting = this.getSortingData('Status', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('status', { sortId: sortId })), sortingData : statusSorting
        });
        //console.log("Testing interactions", statusSorting)
        break;
      case 'assignto':
        let assignedToSorting = this.getSortingData('Assigned To', handleSortingOrder);
        this.setState({
          interactions: [...interactions].sort(this.sortInteration('assignedTo', { sortId: sortId })), sortingData : assignedToSorting
        });
        //console.log("Testing interactions", assignedToSorting)
        break;
      default:
        break;
    }
  }

  

  getInteractionList(paginate) {
    // utils.showLoader();
    let data = {
      lastEvaluatedKey: paginate === 'back' ? null : this.state.lastEvaluatedKey
    };
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_INTERACTION_FROM_JSON, data,async function (result) {
      if(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin){
      utils.showLoader();
      }
      //console.log("jsonurl",result);
      if(utils.getSessionItem("role") === RoleConstant.Admin || utils.getSessionItem("role") === RoleConstant.QualityMonitor)
      { utils.showLoader();
        await fetch(result.interactions, {
          mode: 'cors',
          headers: {
              'Access-Control-Allow-Origin':'*'
          },
          method: 'GET',
          body: null,
        })
        .then((response) => response.json())
        .then((s3ResponseJson) => {
          //console.log("s3 response Data",s3ResponseJson);
          let homeRole= utils.getSessionItem("role");
          let listBasedOnRole =  self.getFilterOnRole(s3ResponseJson,homeRole);
          //console.log("listBasedOnRole",listBasedOnRole);
          self.setState({ roleInteractions: listBasedOnRole});
          utils.hideLoader();
        })
        .catch((error) => {
          console.error(error);
          
        });
    
      }
      let interactions = (utils.getSessionItem("role") === RoleConstant.Admin || utils.getSessionItem("role") === RoleConstant.QualityMonitor)?self.state.roleInteractions:result.interactions;
      console.log("interactions check",interactions)
      // if(interactions.length>0) {
      let allinteractions=self.state.tempInteractions.concat(result.interactions)
      // self.state.tempInteractions=[].concat(result.interactions);
      self.setState({tempInteractions:allinteractions});
      const interactionOrdered = {};
      interactions.forEach((interaction) => {
        if (!interactionOrdered.hasOwnProperty(interaction.interactionId)) {
          interactionOrdered[interaction.interactionId] = [];
        }
        interactionOrdered[interaction.interactionId].push(interaction);
        //console.log(interaction);
      });
      //console.log(interactionOrdered);
      // if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
      //   Object.keys(interactionOrdered).forEach((interactionId) => {
      //     let mlCompleted = true;
      //     interactionOrdered[interactionId].forEach((subcase) => {
      //       if (!subcase.hasOwnProperty('mlResult') || !subcase.mlResult) {
      //         // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
      //         mlCompleted = false;
      //       }
      //     });
      //     if (mlCompleted) {
      //       delete interactionOrdered[interactionId];
      //     }
      //   });
      // }
      interactions = [];
      Object.keys(interactionOrdered).forEach((interactionId) => {
        interactions = interactions.concat(interactionOrdered[interactionId]);
      });

      // interactions = interactions.concat(self.state.interactions);

      console.log("interactions ++ " + JSON.stringify(interactions));
      var joined = paginate === 'back' ? interactions : self.state.interactions.concat(interactions);
      self.setState({ interactions: joined, interactionsSort: joined });
      // utils.hideLoader();
      if(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin){
      setTimeout(function () {
        // window.location.hash = path;
        // window.location = path;
        utils.hideLoader();
      }, 200);
    } else {
      self.loading = false;
    }

      // self.loading = false;
      if (result.hasOwnProperty('lastEvaluatedKey')) {
        self.setState({ lastEvaluatedKey: result.lastEvaluatedKey });
      }
      else {
        self.setState({ lastEvaluatedKey: null }, function () {
          if (paginate) {
            self.updateLastPage();
          }
        });
        // if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
        //   const interactionOrdered1 = {};
        //   console.log(self.state.tempInteractions,'self.state.interactions')
        //   self.state.tempInteractions.forEach((interaction) => {
        //     if (!interactionOrdered1.hasOwnProperty(interaction.interactionId)) {
        //       interactionOrdered1[interaction.interactionId] = [];
        //     }
        //     interactionOrdered1[interaction.interactionId].push(interaction);
        //     //console.log(interaction);
        //   });

        //   Object.keys(interactionOrdered1).forEach((interactionId) => {
        //     let mlCompleted = true;
        //     interactionOrdered1[interactionId].forEach((subcase) => {
        //       if (!subcase.hasOwnProperty('mlResult') || !subcase.mlResult) {
        //         // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
        //         mlCompleted = false;
        //       }
        //     });
        //     if (mlCompleted) {
        //       delete interactionOrdered1[interactionId];
        //     }
        //   });
        //   console.log(interactionOrdered1,'interactionOrdered1')
        //  let allInteractions = [];
        //   Object.keys(interactionOrdered1).forEach((interactionId) => {
        //     allInteractions = allInteractions.concat(interactionOrdered1[interactionId]);
        //   });
        //   console.log(allInteractions,'allInteractions');
        //   self.setState({ interactions: allInteractions},()=>{
        //     // self.lastClick();
        //   });
        // }
      }
      // console.log(this.state,'useres')
    // }
    });

    // utils.hideLoader();
  }
  

  getFilterOnRole(list, role) {
    //console.log("homerole",role,list)
    let filteredData = [];
    if(list.length > 0){
      switch(role){
          case RoleConstant.Admin:
              filteredData = list;
              break;
          case RoleConstant.QualityMonitor:
              filteredData = list.filter((data)=>{
                return data.isModelCompleted == false;
              });
              break;
         
          default:
            //console.log("role is not valid");
              break; 
      }
    }
  return filteredData;
  } 

  setLastEvaluatedKey(lastEvaluatedKey) {
    this.setState({ lastEvaluatedKey: lastEvaluatedKey })
  }
  viewInteractionDetails(interactionId) {
    //  debugger
    let self = this
    let data = {
      interactionId: interactionId
    }
    // this.setState({show:true})
    utils.makeRequest(this, EndpointConstant.GET_INTERACTION_DETAILS, data, function (result) {
      // debugger
      const interactions = result;
      self.loading = false;
      self.setState({ interactionDetails: interactions })
      self.setState({ show: true })

    })
  }
  setShow() {
    this.setState({ show: '' });
    // this.getInteractionList('back');
  }
  updateIntercations(details, team) {
    // let changedInteraction=this.state.interactions.filter( function (interaction) {
    //   return interaction.id === id
    // });if
    // if (this.state.searchdata !== null) {
    //   let self = this
    //   let data = {}
    //   if (this.state.searchdata.interactionId !== '') {
    //     data.caseNumber = this.state.searchdata.interactionId
    //   }
    //   if (this.state.searchdata.subcaseNumber !== '') {
    //     data.subcaseNumber = this.state.searchdata.subcaseNumber
    //   }
    //   // if(this.state.createdBy!==''){
    //   //   data.createdBy=this.state.createdBy
    //   // }
    //   if (this.state.searchdata.associatedTeam.length!=0) {
    //     data.associatedTeam = this.state.searchdata.associatedTeam
    //   }
    //   if (this.state.searchdata.selected.length!=0) {
    //     data.datasetId = this.state.searchdata.selected
    //   }
    //   if (this.state.searchdata.country.length!=0) {
    //     data.interactionCountry = this.state.searchdata.country
    //   }
    //   if (this.state.searchdata.channel.length!=0) {
    //     data.channel = this.state.searchdata.channel
    //   }
    //   if (this.state.searchdata.status.length!=0) {
    //     data.status = this.state.searchdata.status
    //   }
    //   if (this.state.searchdata.assignedTo.length!=0) {
    //     data.assignedTo = this.state.searchdata.assignedTo
    //   }
    //   utils.makeRequest(this, EndpointConstant.GET_INTERACTION_FROM_JSON, data, function (result) {
    //     //
    //     const interactions = result.interactions;
    //     // if(interactions.length>0) {
    //     // self.loading = false;
    //     self.setState({ interactions: interactions })
    //     // console.log(this.state,'useres')
    //     // }

    //   })
    // } else {
      for (let i = 0; i < this.state.interactions.length; i++) {
        if (this.state.interactions[i].id === details.id) {
          let stateIntr = this.state.interactions;
          if(utils.getSessionItem("role") === RoleConstant.QualityMonitor && team === RoleConstant.CallCenterAdmin){
            stateIntr[i].assignedTo = details.hasOwnProperty('parentCCATeam')?details.parentCCATeam:stateIntr[i].associatedTeam;
           } else {
          stateIntr[i].assignedTo = team;
          }
          stateIntr[i].status = 'Assigned';
          if(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin || utils.getSessionItem("role") === RoleConstant.TranslationVendor){
            // delete stateIntr[i];
            stateIntr.splice(i, 1);
          }
          this.setState({
            interactions: stateIntr
          })
        }
      }
    }
 // }


  /*
  * on Sort functionality
  */

  
  sortInteration(prop, sortId) {
    return (x, y) => {
      x[prop] === undefined ? x[prop] = "" :'';
      y[prop] === undefined ? y[prop] = "" :'';

      let a = JSON.parse(JSON.stringify(x))
      let b = JSON.parse(JSON.stringify(y))

      if(prop === 'status'){
        a[prop] = a.mlResult === true ? a[prop] + '(Model)' : (a[prop] == "Completed" ? a[prop] + '(Manual)' : a[prop])
        b[prop] = b.mlResult === true ? b[prop] + '(Model)' : (b[prop] == "Completed" ? b[prop] + '(Manual)' : b[prop])
      }
      
      if (sortId.sortId === 'sort') {
        return a[prop].toString().localeCompare(b[prop].toString());
      }
      else if (sortId.sortId === 'sort-up') {
        return b[prop].toString().localeCompare(a[prop].toString());
      }
      else if (sortId.sortId === 'sort-down') {
        return a;
      } 
    }
  }
  

  componentDidMount() {
    let baseUrl = (window.location).href; 
    let id = baseUrl.substring(baseUrl.lastIndexOf('/') + 1);
    if(id!=="home"){
    this.viewInteractionDetails(id)
    }
    this.getInteractionList();
  }
  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }
  setLinkActive(activeLink) {
    if (activeLink === 'error') {
      this.setState({ link: { errorLink: 'active' } })
    } else {
      this.setState({ link: { scorecardLink: 'active' } })
    }
  }
  setLinkInactive(activeLink) {
    if (activeLink === 'error') {
      this.setState({ link: { errorLink: '' } })
    }
    else {
      this.setState({ link: { scorecardLink: '' } })
    }
  }


  colorChangeStatus(status) {
    //  const styles = {
    //    Assigned: '#0F9D58',
    //    Unassigned: '#EA4335',

    // }

    switch (status) {
      case 'Assigned':
        return { color: '#0F9D58' };
      case 'Unassigned':
        return { color: '#EA4335' };
      default:
        return {};
    }
  }

  getDatasetListForBioconnect() {
    //console.log("downn",this.state.sId);
    
      let data = {
        downloadRequest: this.state.downloadRequest,
        sId: this.state.sId
      };
      let self = this;
        utils.makeRequestInBackground(this, EndpointConstant.DOWNLOAD_INTERACTION_STATUS, data, function (result) {
          //console.log("downResulttt",result);

          if(self.state.downloadRequest == "Processing"){
          self.setState({
            downloadRequest: result.downloadRequest,
            downloadUrl: result.url,
          })
          result.url != ''? window.open(result.url, 'Download'): '';
             
          }        

        });
        
    }

  extractDataFromBioConnect(event){
    event.preventDefault();
    //console.log("extract Data from bio connect");
    
        clearInterval(this.interval);
        //console.log("this state",this.state);
        this.interval =null;
        this.setState({
          showDataSync: true
        });
        this.downloadInteraction(event);
        this.interval = setInterval(() => {
          let val = this.state.extractValue;
          val += Math.floor(Math.random() * 10) + 1;
         if(val >= 100) {
            val = 100;
         }

         this.setState({
          extractValue: val
      });
         //console.log("downloadss")
         this.state.downloadRequest == "Processing"? this.getDatasetListForBioconnect() : '';

          if(this.state.downloadRequest == "Completed") {
            this.hideDataSyncOnCancel();             
            this.setState({
              extractSuccess: true,
              extractValue:0,
              extractResponseMsg: MessageConstant.DOWNLOADED_INTERACTION,
              extractResponseFlagIcon: 'success'
            });
            clearInterval(this.interval);
            this.interval =null;
          }

        }, 8000);
  }

  

  hideDataSyncOnCancel() {
    //event.preventDefault();
    this.setState({
      showDataSync: false,
      extractValue: 0,
     // downloadRequest: 'Processing',
      downloadUrl: '',
      sId: '',
      deleteConfirmation: false
    });
    clearInterval(this.interval);
    this.interval =null;
  }

  handleExtractSuccessClose(){
    this.setState({
      extractSuccess: false
    })
  }

 getdatasetIds(ids) {
   //console.log("ids",ids);
   let listIds =[];
   if(ids.length > 0) {
    listIds = ids.map((dataset)=>{
     return dataset.id;
     });
   }
   //console.log("listIds",listIds);
   //if(this.state.searchdata.selected.length == 0) {
     this.setState({
        datasetIds: listIds
     });
   //}
 }
  render() {
    const intercations = this.state.interactions;

    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;

    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = intercations && intercations.length > 0 && intercations.slice(indexOfFirstTodo, indexOfLastTodo);

    const interactions = currentTodos && currentTodos.map((data) => {
     
      return (
        
          <tr key={data.id}>
          {/* <td>{data.interactionId.toUpperCase()}</td> */}
          {/* <td title={data.interactionId}>{data.interactionId}</td> */}
          <td title={data.subcaseNumber}>{this.add3Dots(data.subcaseNumber && data.subcaseNumber.toUpperCase(),16)}</td>
        {/* <td title={data.interactionCreatedByName}>{this.add3Dots(data.interactionCreatedByName, 10)}</td> */}
        <td title={data.associatedTeam}>{this.add3Dots(data.associatedTeam, 17)}</td>
        <td title={data.datasetName}>{this.add3Dots(data.datasetName, 10)}</td>
        {/* <td title={data.language}>{this.add3Dots(data.language, 20)}</td> */}
        <td title={data.interactionCountry}>{this.add3Dots(data.interactionCountry, 10)}</td>
        <td title={data.channel}>{this.add3Dots(data.channel, 10)}</td>
        {/* <td title = {moment.utc(data.interactionModifiedOn).format('MM/DD/YYYY hh:mm A')}><Moment format="MM/DD/YYYY hh:mm A">{data.interactionModifiedOn}</Moment></td> */}
        <td title = {moment.utc(data.interactionModifiedOn).format('MM/DD/YYYY hh:mm A')}>{moment.utc(data.interactionModifiedOn).format('MM/DD/YYYY hh:mm A')}</td>
        <td title={data.status} style={this.colorChangeStatus(data.status)}>{data.mlResult === true ? data.status + '(Model)' : (data.status == "Completed" ? data.status + '(Manual)' : data.status)}</td>
        <td title={data.assignedTo}>{siteinfo.AssignedTo[data.assignedTo] || this.add3Dots(data.assignedTo, 15)}</td>
          {/* <td> <Button variant="secondary" type="submit" className='textcolor' onClick={() => this.viewDetails(data)}>View Details
  </Button></td> */}
          {/* <td> <Button variant="secondary" type="submit" className='textcolor' onClick={()=>this.viewInteractionDetails(data.id)}>View Details
  </Button></td> */}
          <td> <a className='view-details' onClick={() => this.viewInteractionDetails(data.id)}><i className="fa fa-eye"></i>&nbsp;View Details
  </a></td>
        </tr>

      );
    });


    const pageNumbers = [];
    if (interactions) {
      for (let i = 1; i <= Math.ceil(intercations.length / todosPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <ListGroup.Item key={number} className='active list-item-padding' id={number} onClick={this.handleClick}><a id={number} >{number}</a></ListGroup.Item>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <ListGroup.Item key={number} className={number === currentPage ? 'active list-item-padding' : 'list-item-padding'} id={number} onClick={this.handleClick}><a id={number}>{number}</a></ListGroup.Item>
        )
      }
      return null;
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnIncrementClick}><a> &hellip; </a></ListGroup.Item>
    }
    let pageDecrementBtn = null;

    if (lowerPageBound >= 1) {
      pageDecrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnDecrementClick}><a> &hellip; </a></ListGroup.Item>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive}><span id="btnPrev"> <Image src="/Images/ico-arrow-left-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive} onClick={this.btnPrevClick}><a id="btnPrev"><Image src="/Images/ico-arrow-left.png" className='arrow-left-right'></Image> </a></ListGroup.Item>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive} onClick={this.btnNextClick}><a id="btnNext" > <Image src="/Images/ico-arrow-right.png" className='arrow-left-right'></Image> </a></ListGroup.Item>
    }
    let frstpage = null;
    if (isPrevBtnActive === 'disabled') {
      frstpage = <ListGroup.Item className={isPrevBtnActive}><span id="btnNext"><Image src="/Images/ico-arrow-left-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      frstpage = <ListGroup.Item className={isPrevBtnActive} onClick={this.firstclick}><a > <Image src="/Images/ico-arrow-left-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }
    let lastpage = null;
    if (isNextBtnActive === 'disabled') {
      lastpage = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      lastpage = <ListGroup.Item className={isNextBtnActive} onClick={this.lastClick}><a ><Image src="/Images/ico-arrow-right-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }


    return (
      <div>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar link={this.state.link} />
        </div>
        <div className="menu-content">
          {this.state.show ? '' : <InteractionSearch parentCallback={this.setLastEvaluatedKey} datasetIds={this.getdatasetIds} getSearchResult={this.searchResults} getInteractionList={this.getInteractionList}
            setSearchData={this.setSearchData} searchData={this.state.searchdata} setLastEvaluatedKey={this.setLastEvaluatedKey} countIntercations={intercations} roleInteractions={this.state.roleInteractions}/>}
          {this.state.show ? <ViewDetails interactiondetails={this.state.interactionDetails} hideViewDetails={this.setShow} setLinkActive={this.setLinkActive} setLinkInactive={this.setLinkInactive} updateIntercations={this.updateIntercations} /> : ''}
          {this.state.show ? '' : <div>
             <table>
            <thead>
              <tr>
              {/* <th>Master-Case Number</th> */}
              <th onClick={(e) => this.onSortInteraction('subcaseno', e)}>Sub-Case Number<i className="fas fa-sort" id="sort"></i></th>
                {/* <th onClick={(e) => this.onSortInteraction('createdby', e)}>Created By<i className="fas fa-sort" id="sort"></i></th> */}
                <th onClick={(e) => this.onSortInteraction('assocteam', e)}>Associate Team<i className="fas fa-sort" id="sort"></i></th>
                <th onClick={(e) => this.onSortInteraction('datasets', e)}>Dataset<i className="fas fa-sort" id="sort"></i></th>
                {/* <th>Language</th> */}
                <th onClick={(e) => this.onSortInteraction('casectry', e)}>Country<i className="fas fa-sort" id="sort"></i></th>
                <th onClick={(e) => this.onSortInteraction('channel', e)}>Channel<i className="fas fa-sort" id="sort"></i></th>
                <th onClick={(e) => this.onSortInteraction('modifiedon', e)}>Last Modified Date<i className="fas fa-sort" id="sort"></i></th>
                <th onClick={(e) => this.onSortInteraction('status', e)}>Status<i className="fas fa-sort" id="sort"></i></th>
                <th onClick={(e) => this.onSortInteraction('assignto', e)}>Assigned To<i className="fas fa-sort" id="sort"></i></th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {interactions}
            </tbody>
          </table>
          </div>}
          {this.state.show ? '' :
            <Row className='padding-top'>
              <ListGroup className="pageNumbers float-left pno">
                {frstpage}
                {/* {renderfirst} */}
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                {lastpage}
              </ListGroup>
             
              
              <Col > {this.state.searchdata ?<span className='verticalAlign'>Page {this.state.currentPage} of {Math.ceil(this.state.interactions.length / this.state.todosPerPage)}  ({intercations.length} Records)</span> : ''}</Col>
              
              <Button variant="secondary" disabled={this.state.interactions.length===0} className=' download-subcases button-color btn btn-primary' onClick={this.extractDataFromBioConnect}>Download Sub-Cases</Button>
              
            </Row>}
        </div>
        <Modal show={this.state.showDataSync} onHide={this.hideDataSyncOnCancel} className="dataset-extract-dialog">
          <Modal.Header closeButton>
            <Modal.Title>DOWNLOAD SUB-CASES </Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <ProgressBar style={{'height':'10px','borderRadius': '5px','border':'1px solid #0063C3','background':'#F2F2F2'}} showValue={false} value={this.state.extractValue} />
           <p className="progressMsg">Downloading is in progress...</p>
           <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button variant="primary" className="clear" onClick={this.handleDeleteConfirmation}>
              Cancel
            </Button>
          </Modal.Footer>
         </Modal>

         <PopupModels showPopup={this.state.extractSuccess} iconFlag={this.state.extractResponseFlagIcon} message={this.state.extractResponseMsg} handleClosePopup={this.handleExtractSuccessClose.bind(this)} />
      
         <ConfirmationModal showModal={this.state.deleteConfirmation}
        handleCloseModal={this.handleDeleteConfirmation.bind(this)}
        message={MessageConstant.CANCEL_DOWNLOAD}
        navigate={this.hideDataSyncOnCancel} />

      </div>
    );
  }
}

export default Home;
