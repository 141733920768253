
import React, { Component } from 'react';
import './ScoreCard.css';
import { Col, Row, Button, Form,Modal } from 'react-bootstrap'
import utils from './../../utils';
import RoleConstant from "./../../constants/RoleConstant";
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from './../../constants/MessageConstant';
// import RouterConstant from './../../constants/RouterConstant';
import CommonService from '../../commonService/CommonService';
import PopupModels from './../PopupModels/PopupModels';
import PropTypes from 'prop-types';
import ViewScoreCardDetails from './ViewScoreCardDetails';
// import moment from 'moment';
import CommentHistory from './../ErrorList/CommentHistory';

class EditScoreCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.details,
      scorecardUpdateModel: false,
      valid: false,
      error: false,
      countTrue: 0,
      countFalse: 0,
      percent: 0,
      scorecardId: '',
      showViewDetails: false,
      addScorecardFail: false,
      scorecardDetails: [],
      AEID_Q2_AE_Missed_QualityMonitorQC_Error: '',
      PC_Q1_IPRC_Match_QualityMonitorQC_Error: '',
      PCID_Q2_Missed_QualityMonitorQC_Error: '',
      AE_Q3_IPRC_QualityMonitorQC_Error: '',
      AE_Q2_HCP_QualityMonitorQC_Error: '',
      AE_Cap_IRPC_Match_QualityMonitorQC_Error: '',
      scorecardErrorModel: false,
      newcomment:'',
      comment: this.props.details.comments,
      showComment: false,
      scorecardUserError: false,
      hideView: false,
    }
    //console.log("sfdsfsd", this.props);
    this.updateScorecard = this.updateScorecard.bind(this);
    this.handleChangeYesNo = this.handleChangeYesNo.bind(this);
    this.handleUpdateClose = this.handleUpdateClose.bind(this);
    this.goBackTo = this.goBackTo.bind(this);

    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
    this.routetoQM=this.routetoQM.bind(this);
    this.validationAmgenQC = this.validationAmgenQC.bind(this);
  }

  goBackTo() {
    if (this.props.scoreCard === 'new') {
      // this.setState({scorecardDetails: this.props.details},()=>{this.setState({showViewDetails:true })})
      // this.props.hideEditScoreCard()
      this.props.hideDetails()
    } else {
      if (this.state.scorecardId !== '') {
        this.setState({ showViewDetails: true })
      } else {
        if (this.props.scoreCard === 'edit') {
          this.props.hideDetails();
        } else {
          // this.props.hideDetails()
          this.props.hideEditScoreCard()
        }
      }
    }
  }
  isValidForm() {
    let data = {
      ...this.state
    }
    if (utils.getSessionItem("role") === RoleConstant.QualityMonitor && data.AE_Cap_IRPC_Match !== '' &&
      data.AE_Cap_IRPC_Match_QualityMonitorQC && data.AEID_Q2_AE_Missed && data.AEID_Q2_AE_Missed_QualityMonitorQC &&
      data.AE_Q2_HCP && data.AE_Q2_HCP_QualityMonitorQC && data.AE_Q3_IPRC && data.AE_Q3_IPRC_QualityMonitorQC && data.PCID_Q2_Missed &&
      data.PCID_Q2_Missed_QualityMonitorQC) {
      this.setState({ valid: true })
    }
  }
  routetoQM(){
    let self = this;
    let data = {
      ...this.state
    }
    let viewData = this.props.details;
    let userNameQM = this.state.qualityMonitorQCBy;
    data.QMAssociate = this.state.QMAssociate.trim()
    data.qualityMonitorQCBy = this.state.qualityMonitorQCBy.trim()
    delete data.className;
    delete data.createdAt;
    delete data.modifiedAt;
    delete data.valid;
    delete data.comments;
    if((this.props.scoreCard === 'editquality' || this.props.scoreCard==='edit') && (utils.getSessionItem("userId")!==this.props.details.createdBy && this.props.details.stage==='2') && this.state.qualityMonitorQCBy){
      data.type = 'QUALITYMONITOR_ASSIGN_TO_QUALITYMONITOR'
      data.assignedTo = RoleConstant.QualityMonitor;
      if(this.state.newcomment!='' && this.state.newcomment!=undefined){
      data.comments=this.state.newcomment;
      }
      data.stage='1';
      //console.log("data check",data);
      utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            scorecardUpdateModel: true,
            scorecardId: result.scorecardId,
            hideView: true
          },()=>{
            self.props.updateIntercations(viewData,null,data.assignedTo,userNameQM)
          });
        }
        if(result.hasOwnProperty('errorCode') && result.errorCode=='S_USER_NOT_EXIST') {
          self.setState({scorecardUserError:true});
        }
      })
    }
    else if (utils.getSessionItem("role") === RoleConstant.Admin && this.validationAmgenQC()) {
      data.type = 'ADMIN_ASSIGN_TO_QUALITYMONITOR';
      data.assignedTo = RoleConstant.QualityMonitor;      
      data.stage='2'
      data.Quality_Percentage_INT = parseFloat(data.Quality_Percentage_INT).toFixed(2);      
      utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            scorecardUpdateModel: true,
            scorecardId: result.scorecardId,
            hideView: true
          },()=>{
            self.props.updateIntercations(viewData,null,data.assignedTo,null)
          });
        }
      });
    }
    else{
      this.setState({ scorecardErrorModel: true })
    }
  }
  updateScorecard() {
    let self = this;
    let data = {
      ...this.state
    }
    data.QMAssociate = this.state.QMAssociate.trim()
    data.qualityMonitorQCBy = this.state.qualityMonitorQCBy.trim()
    delete data.className;
    delete data.createdAt;
    delete data.modifiedAt;
    delete data.valid;
    if (utils.getSessionItem("role") === RoleConstant.QualityMonitor && data.AE_Cap_IRPC_Match !== '' && data.PC_Q1_IPRC_Match && 
    data.AEID_Q2_AE_Missed && data.AE_Q2_HCP && data.AE_Q3_IPRC && data.PCID_Q2_Missed && 
    this.state.QMAssociate && data.bioconnect_Match_capturedIn_probing_decisionTree_originalQM) {
      this.setState({ valid: true })
      // if(this.state.valid){
      //console.log(data,'data')
      if (this.props.scoreCard === 'new') {
        if (this.state.attachments.length > 0) {
          data.attachmentIncluded = 'Yes'
        }
        else {
          data.attachmentIncluded = 'No'
        }

        delete data.id;
        delete data.agentNotes;
        delete data.comments;
        data.interactionId = this.state.id;
        data.comments=this.state.newcomment
        data.stage='2';
        utils.makeRequest(this, EndpointConstant.ADD_SCORE_CARD, data, function (result) {
          if (result.error === false || result.error === "false") {
            self.setState({
              scorecardUpdateModel: true,
              scorecardId: result.scorecardId
            });
          } 
          if(result.hasOwnProperty("errorCode") && result.errorCode === 'S_ALREADY_CREATED') {
            self.setState({addScorecardFail: true});
          }
        })
      } else if(data.AE_Q2_HCP && data.AE_Q3_IPRC && this.state.qualityMonitorQCBy){
        if((this.props.scoreCard === 'editquality' || this.props.scoreCard==='edit') && utils.getSessionItem("userId")===this.props.details.createdBy && this.props.details.stage==='1'){
        data.type = 'QUALITYMONITOR_ASSIGN_TO_QUALITYMONITOR'
        data.assignedTo = RoleConstant.QualityMonitor;
        delete data.comments;
        // data.comments=this.state.newcomment
        if(this.state.newcomment!='' && this.state.newcomment!=undefined){
          data.comments=this.state.newcomment;
        }
        data.stage='2';
        utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
          if (result.error === false || result.error === "false") {
            self.setState({
              scorecardUpdateModel: true,
              scorecardId: result.scorecardId
            });
          } 
          if(result.hasOwnProperty('errorCode') && result.errorCode=='S_USER_NOT_EXIST') {
            self.setState({scorecardUserError:true});
          }
        })
      } else if(data.AE_Cap_IRPC_Match_QualityMonitorQC && data.PC_Q1_IPRC_Match_QualityMonitorQC &&
        data.AEID_Q2_AE_Missed_QualityMonitorQC && data.AE_Q2_HCP && data.AE_Q2_HCP_QualityMonitorQC && data.AE_Q3_IPRC && 
        data.AE_Q3_IPRC_QualityMonitorQC && data.PCID_Q2_Missed_QualityMonitorQC && this.state.qualityMonitorQCBy && 
        data.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC){
        data.type = 'QUALITYMONITOR_ASSIGN_TO_ADMIN'
        data.assignedTo = RoleConstant.Admin;
        delete data.comments;
        if(this.state.newcomment!='' && this.state.newcomment!=undefined){
          data.comments=this.state.newcomment;
        }
        // data.comments=this.state.newcomment
        data.stage='Admin';
        let viewData = this.props.details;
        let userNameQM = this.state.qualityMonitorQCBy;
        let self = this;
        utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
          if (result.error === false || result.error === "false") {
            self.setState({
              scorecardUpdateModel: true,
              scorecardId: result.scorecardId,
              hideView: true
            },()=>{
              self.props.updateIntercations(viewData,null,data.assignedTo,userNameQM)
            });
          }
        })
      }
      else{
        this.setState({ scorecardErrorModel: true })
      }
      }else{
        this.setState({ scorecardErrorModel: true })
      }
    } else if (utils.getSessionItem("role") === RoleConstant.Admin && this.validationAmgenQC()) {
        this.setState({ valid: true })
        let data = this.state;              
      
        data.type = 'ADMIN_MODIFY';
        data.assignedTo = RoleConstant.Admin;      
        data.Quality_Percentage_INT = parseFloat(data.Quality_Percentage_INT).toFixed(2);
        let self = this      
        let viewData = this.props.details;
        let fullName = utils.getSessionItem("firstName")+" "+ utils.getSessionItem("lastName")

        utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
          if (result.error === false || result.error === "false") {
            self.setState({
              scorecardUpdateModel: true,
              scorecardId: result.scorecardId,
              hideView: true
            },()=>{
              self.props.updateIntercations(viewData,fullName,data.assignedTo,"")
            });
          }
        });      
    } else {
      this.setState({ scorecardErrorModel: true })
    }
    //console.log("updatescorecard", data);

  }
  handleErrorClose() {
    this.setState({ scorecardErrorModel: false, addScorecardFail: false , scorecardUserError: false })
  }
  handleUpdateClose(event) {
    event.preventDefault();
    let self = this
    let data = {
      id: this.state.scorecardId
    }
    // this.setState({show:true})
    utils.makeRequest(this, EndpointConstant.SCORECARD_DETAILS, data, function (result) {
      const scorecardDetails = result.scorecardDetails;
      self.loading = false;
      scorecardDetails[0].datasetName= self.props.scoreCard==='new'?self.props.details.datasetNameView:self.props.details.datasetName;
      //console.log("editfnview",scorecardDetails);
      self.setState({ scorecardDetails: scorecardDetails, scorecardUpdateModel: false },
        () => {
          self.setState({ showViewDetails: true })
        })
    }
    )
    // this.setState({
    //   scorecardUpdateModel: false
    // });
    // setTimeout(() => {
    //   if(this.props.scoreCard!=='new' && this.props.scoreCard!=='edit'){
    //   // this.goBackTo();
    //   window.location.reload(false)
    //   }else{
    //     utils.gotoPage(RouterConstant.SCORE_CARD_LINK)
    //   }
    // }, 500);
  }
  caliculation() {
    let count = {
      trueCount: 0,
      falseCount: 0
    }
    //  let trueCount=0
    //  let falseCount=0
    if (this.state.AEID_Q2_AE_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AEID_Q2_AE_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    return count;
  }
  aeidMatch() {
    if (this.state.AEID_Q2_AE_Missed_QualityMonitorQC !== undefined && this.state.AEID_Q2_AE_Missed_QualityMonitorQC !== '' && this.state.AEID_Q2_AE_Missed_Amgen !== '' && this.state.AEID_Q2_AE_Missed_Amgen !== undefined) {
      if ((this.state.AEID_Q2_AE_Missed_QualityMonitorQC === this.state.AEID_Q2_AE_Missed_Amgen)) {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AEID_Q2_AE_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Cap_IRPC_Match() {
    if (this.state.AE_Cap_IRPC_Match_QualityMonitorQC !== undefined && this.state.AE_Cap_IRPC_Match_QualityMonitorQC !== '' && this.state.AE_Cap_IRPC_Match_Amgen !== '' && this.state.AE_Cap_IRPC_Match_Amgen !== undefined) {
      if ((this.state.AE_Cap_IRPC_Match_QualityMonitorQC === this.state.AE_Cap_IRPC_Match_Amgen)) {
        this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Q2_HCP() {
    if (this.state.AE_Q2_HCP_QualityMonitorQC !== undefined && this.state.AE_Q2_HCP_QualityMonitorQC !== '' && this.state.AE_Q2_HCP_Amgen !== '' && this.state.AE_Q2_HCP_Amgen !== undefined) {
      if ((this.state.AE_Q2_HCP_QualityMonitorQC === this.state.AE_Q2_HCP_Amgen)) {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Q2_HCP_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Q3_IPRC() {
    if (this.state.AE_Q3_IPRC_QualityMonitorQC !== undefined && this.state.AE_Q3_IPRC_QualityMonitorQC !== '' && this.state.AE_Q3_IPRC_Amgen !== '' && this.state.AE_Q3_IPRC_Amgen !== undefined) {
      if ((this.state.AE_Q3_IPRC_QualityMonitorQC === this.state.AE_Q3_IPRC_Amgen)) {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Q3_IPRC_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  PCID_Q2_Missed() {
    if (this.state.PCID_Q2_Missed_QualityMonitorQC !== undefined && this.state.PCID_Q2_Missed_QualityMonitorQC !== '' && this.state.PCID_Q2_Missed_Amgen !== '' && this.state.PCID_Q2_Missed_Amgen !== undefined) {
      if ((this.state.PCID_Q2_Missed_QualityMonitorQC === this.state.PCID_Q2_Missed_Amgen)) {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ PCID_Q2_Missed_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  PC_Q1_IPRC_Match() {
    if (this.state.PC_Q1_IPRC_Match_QualityMonitorQC !== undefined && this.state.PC_Q1_IPRC_Match_QualityMonitorQC !== '' && this.state.PC_Q1_IPRC_Match_Amgen !== '' && this.state.PC_Q1_IPRC_Match_Amgen !== undefined) {
      if ((this.state.PC_Q1_IPRC_Match_QualityMonitorQC === this.state.PC_Q1_IPRC_Match_Amgen)) {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ PC_Q1_IPRC_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  bioconnect_Match_capturedIn_probing_decisionTree_originalQM() {
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !== undefined && this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !== '' && this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !== '' && this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !== undefined) {
      if ((this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC === this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC)) {
        this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  handleChangeYesNo(e) {
    //console.log("attachmentIncluded", e.currentTarget.value);
    //console.log("attasdfks name", e.target.name);
    let name = e.target.name
    this.setState({
      [e.target.name]: e.currentTarget.value
    }, () => {
      switch (name) {
        case 'AEID_Q2_AE_Missed_Amgen':
          this.aeidMatch();
          break;
        case 'AEID_Q2_AE_Missed_QualityMonitorQC':
          this.aeidMatch();
          break;
        case 'AE_Cap_IRPC_Match_Amgen':
          this.AE_Cap_IRPC_Match();
          break;
        case 'AE_Cap_IRPC_Match_QualityMonitorQC':
          this.AE_Cap_IRPC_Match();
          break;
        case 'AE_Q2_HCP_Amgen':
          this.AE_Q2_HCP();
          break;
        case 'AE_Q2_HCP_QualityMonitorQC':
          this.AE_Q2_HCP();
          break;
        case 'AE_Q3_IPRC_Amgen':
          this.AE_Q3_IPRC();
          break;
        case 'AE_Q3_IPRC_QualityMonitorQC':
          this.AE_Q3_IPRC();
          break;
        case 'PCID_Q2_Missed_Amgen':
          this.PCID_Q2_Missed();
          break;
        case 'PCID_Q2_Missed_QualityMonitorQC':
          this.PCID_Q2_Missed();
          break;
        case 'PC_Q1_IPRC_Match_Amgen':
          this.PC_Q1_IPRC_Match();
          break;
        case 'PC_Q1_IPRC_Match_QualityMonitorQC':
          this.PC_Q1_IPRC_Match();
          break;
        case 'bioconnect_Match_capturedIn_probing_decisionTree_amgenQC':
          this.bioconnect_Match_capturedIn_probing_decisionTree_originalQM();
          break;
        case 'bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC':
          this.bioconnect_Match_capturedIn_probing_decisionTree_originalQM();
          break;
        default:
          break;
      }
      if (utils.getSessionItem("role") === RoleConstant.QualityMonitor && this.state.AE_Cap_IRPC_Match !== '' &&
        this.state.AE_Cap_IRPC_Match_QualityMonitorQC && this.state.AEID_Q2_AE_Missed && this.state.AEID_Q2_AE_Missed_QualityMonitorQC &&
        this.state.AE_Q2_HCP && this.state.AE_Q2_HCP_QualityMonitorQC && this.state.AE_Q3_IPRC && this.state.AE_Q3_IPRC_QualityMonitorQC && this.state.PCID_Q2_Missed &&
        this.state.PCID_Q2_Missed_QualityMonitorQC && this.state.comments && this.state.QMAssociate && this.state.qualityMonitorQCBy) {
        this.setState({ valid: true })
      }
    });
  }

  showHistory() {
    this.setState({showComment: true});
  }
  hideHistory() {
    this.setState({showComment:false});
  }
  
  validationAmgenQC() {
    let check = true;
    const { AEID_Q2_AE_Error_Reconciliation, AE_Cap_IRPC_Match_Error_Reconciliation, AE_Q2_HCP_Error_Reconciliation, AE_Q3_IPRC_Error_Reconciliation, PCID_Q2_Missed_Error_Reconciliation, PC_Q1_IPRC_Error_Reconciliation, bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation } = this.state;
    let qcField = {
      AEID_Q2_AE_Error_Reconciliation: AEID_Q2_AE_Error_Reconciliation,
      AE_Cap_IRPC_Match_Error_Reconciliation: AE_Cap_IRPC_Match_Error_Reconciliation,
      AE_Q2_HCP_Error_Reconciliation: AE_Q2_HCP_Error_Reconciliation,
      AE_Q3_IPRC_Error_Reconciliation: AE_Q3_IPRC_Error_Reconciliation,
      PCID_Q2_Missed_Error_Reconciliation: PCID_Q2_Missed_Error_Reconciliation,
      PC_Q1_IPRC_Error_Reconciliation: PC_Q1_IPRC_Error_Reconciliation,
      bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation
    }
    Object.values(qcField).forEach(
      (val) => (val === '' || val === '-') && (check = false)
    );
    return check;
  }
  

  render() {
    // this.isValidForm();
    // let scorecarDetails=this.props.details;
    //console.log(scorecarDetails,"editscorecardetails")

    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const currentUserId = utils.getSessionItem("userId");    
    const AdminModifyMode = utils.getSessionItem('role') === RoleConstant.Admin && this.state.assignedTo === RoleConstant.Admin && this.state.sampleSet !=='' && this.state.sampleCreatedBy  === currentUserId && this.state.amgenQCBy !== '' ? true : false

    const ifrOptions = CommonService.ifrType.map((status, i) => {
      return (
        <option key={i} value={status.value}>{status.value}</option>
      )
    })
    const channelOptions = siteinfo.Channel.map((channels, i) => {
      return (
        <option key={i} value={channels}>{channels}</option>
      )
    });
    // const AEID_Q2_AE_Missed=this.state.errors.AEID_Q2_AE_Missed
    let scoreDetails = this.state;
     //console.log("edit score card",scoreDetails)
    const { caseType, channel, QMAssociate, qualityMonitorQCBy, amgenQCBy, AEID_Q2_AE_Missed,
      AEID_Q2_AE_Missed_QualityMonitorQC, AEID_Q2_AE_Missed_Amgen, AE_Cap_IRPC_Match,
      AE_Cap_IRPC_Match_QualityMonitorQC, AE_Cap_IRPC_Match_Amgen, AE_Q2_HCP, AE_Q2_HCP_QualityMonitorQC,
      AE_Q2_HCP_Amgen, AE_Q3_IPRC, AE_Q3_IPRC_QualityMonitorQC, AE_Q3_IPRC_Amgen, PCID_Q2_Missed,
      PCID_Q2_Missed_QualityMonitorQC, PCID_Q2_Missed_Amgen, PC_Q1_IPRC_Match,
      PC_Q1_IPRC_Match_QualityMonitorQC, PC_Q1_IPRC_Match_Amgen, Total_Error_Reconciliation,
      Quality_Percentage_INT, subcaseNumber, attachments, attachmentIncluded,
      bioconnect_Match_capturedIn_probing_decisionTree_originalQM, bioconnect_Match_capturedIn_probing_decisionTree_amgenQC
      , bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC ,datasetNameView,datasetName} = scoreDetails;
    // const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const role = utils.getSessionItem("role");
    // console.log(siteinfo.ScorecardLabels);
    // const scoreCardLables = siteinfo.ScorecardLabels;
    const backButton = <Button variant="secondary" className="mt-4 clear follow-up-back mr-2" onClick={this.goBackTo}>
      <img src="Images/icon-arrow-left.png" className="btn-back" alt='Amgen' />Back</Button>
    const UpdateButton = <Button onClick={this.updateScorecard} className='mt-4 button-color follow-up-back'>{AdminModifyMode ? 'Modify' : 'Submit'}</Button>    
    const routetoQM=(((this.props.scoreCard==='editquality' || this.props.scoreCard==='edit') && (this.props.details.stage==='2' && currentUserId!==this.props.details.createdBy)) || AdminModifyMode)?<Button onClick={this.routetoQM} className='mt-4 button-color mr-2 follow-up-back'>Route To QM</Button>:''
    const dropdown = (text, value, amgen) => {
      //console.log(this.props.scoreCard,currentUserId,this.props.details.createdBy,this.props.details.stage,amgen,'details')
      // console.log(role === RoleConstant.QualityMonitor && amgen === 'amgen' || (this.props.scoreCard === 'new' && amgen==='stage2') ||(this.props.scoreCard==='editquality' || this.props.scoreCard==='edit' && ( ( this.props.details.stage=='2' && amgen==='stage2' && currentUserId!==this.props.details.createdBy))))
      return (<Form.Control as='select' size="sm" name={text} value={value} onChange={this.handleChangeYesNo}
        disabled={(role === RoleConstant.QualityMonitor && amgen === 'amgen' || (this.props.scoreCard === 'new' && amgen==='stage2') ||((this.props.scoreCard==='editquality' || this.props.scoreCard==='edit') && ((this.props.details.stage==='2' && amgen==='stage1' && currentUserId!==this.props.details.createdBy) || ( this.props.details.stage==='1' && amgen==='stage2' && currentUserId===this.props.details.createdBy)))) || (AdminModifyMode && (amgen === 'stage1' || amgen === 'stage2')) ? true : null} >
        <option value=''>-Select-</option>
        <option value='NA'>NA</option>
        <option value='No' key='No'>No</option>
        <option value='Yes' key='Yes'>Yes</option>
      </Form.Control>)
    }
    let commentHist;
    if(this.state.comment) {
     commentHist = this.state.comment;
  }
    //console.log("editcommenthistory", commentHist);
    

    return (
      <div>
        <div className="content-layout">
          {this.state.showViewDetails ? <ViewScoreCardDetails details={this.state.scorecardDetails} hideView={this.state.hideView} scoreDetails='new' updateIntercations={this.props.updateIntercations} scListingPageTriggerFn = {this.props.scListingPageTriggerFn} /> :
            <div>
              <div className="score-details-layout">
                <div className="score-card">
                  <Form onSubmit={this.handleSearch} className="editScoreForm">
                    <Form.Row>
                      <Col md='2'>
                        <Form.Label>Sub-Case Number</Form.Label>
                        <Form.Control type='text' disabled name='subcaseNumber' value={subcaseNumber.toUpperCase()} onChange={this.handleChangeYesNo} />
                        {/* </Form.Group> */}
                      </Col>
                      <Col md='2'>
                        <Form.Label>Dataset</Form.Label>
                        <Form.Control type='text' disabled name='datasetName' value={this.props.scoreCard === 'new'?datasetNameView:datasetName} onChange={this.handleChangeYesNo} />
                        {/* </Form.Group> */}
                      </Col>
                      <Col md='3'>
                        <Form.Label>Case Type</Form.Label>
                        <Form.Control as='select' name='caseType' disabled value={caseType} onChange={this.handleChangeYesNo}>
                          {/* <option value="">--- ALL ---</option> */}
                          {ifrOptions}
                        </Form.Control>
                      </Col>
                      <Col md='2'>
                        <Form.Label>Channel</Form.Label>
                        <Form.Control as='select' disabled name='channel' value={channel} onChange={this.handleChangeYesNo}>
                          <option value=''>--Select--</option>
                          {channelOptions}
                        </Form.Control>
                      </Col>
                      <Col className="mt-4 ml-1 attach-include">
                        <Form.Label>Attachment Included?</Form.Label>
                        <Form.Check label="Yes" type='radio' name="attachmentIncluded" id='Yes' value="Yes" checked={this.props.scoreCard === 'new' ? attachments.length > 0 : attachmentIncluded === 'Yes'} disabled onChange={this.handleChangeYesNo} />
                        <Form.Check label="No" type='radio' name="attachmentIncluded" id='No' value="No" checked={this.props.scoreCard === 'new' ? attachments.length === 0 : attachmentIncluded === 'No'} disabled onChange={this.handleChangeYesNo} />
                      </Col>
                      
                    </Form.Row>
                    <Form.Row>
                    <Col md='2'>
                        <Form.Label>QM Associate</Form.Label>
                        <Form.Control type='text' name='QMAssociate' value={QMAssociate} onChange={e => { this.setState({ QMAssociate: e.target.value }) }} disabled={this.props.details.stage==='2' || this.props.details.stage==='Admin'}/>
                      </Col>
                      <Col md='3'>
                        <Form.Label>Quality Monitor QC By</Form.Label>
                        <Form.Control type='text' name='qualityMonitorQCBy' value={qualityMonitorQCBy} onChange={e => { this.setState({ qualityMonitorQCBy: e.target.value }) }} disabled={this.props.details.stage==='1'|| this.props.details.stage==='Admin' || this.props.scoreCard === 'new'}/>
                      </Col>
                      <Col md='2'>
                        <Form.Label>Amgen QC By</Form.Label>
                        <Form.Control type='text' name='amgenQCBy' disabled value={amgenQCBy ? amgenQCBy : '-'} onChange={e => { this.setState({ amgenQCBy: e.target.value }) }} />
                      </Col>
                    </Form.Row>
                  </Form>
                  {/* <Row className='row-end-border'>
                </Row> */}
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th className='original-qm-width'>Original QM Score</th>
                        <th className='qc-error-width'>Quality Monitor QC</th>
                        <th className='qc-error-width'>Amgen QC</th>
                        <th className='qc-error-width'>Error Reconciliation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AEID_Q2_AE_Missed}</b></td>
                        <td>{dropdown('AEID_Q2_AE_Missed', AEID_Q2_AE_Missed,'stage1')}</td>
                        <td>{dropdown('AEID_Q2_AE_Missed_QualityMonitorQC', AEID_Q2_AE_Missed_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('AEID_Q2_AE_Missed_Amgen', AEID_Q2_AE_Missed_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.AEID_Q2_AE_Error_Reconciliation}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Cap_IRPC_Match}</b></td>
                        <td>{dropdown('AE_Cap_IRPC_Match', AE_Cap_IRPC_Match,'stage1')}</td>
                        <td>{dropdown('AE_Cap_IRPC_Match_QualityMonitorQC', AE_Cap_IRPC_Match_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('AE_Cap_IRPC_Match_Amgen', AE_Cap_IRPC_Match_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.AE_Cap_IRPC_Match_Error_Reconciliation}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Q2_HCP}</b></td>
                        <td>{dropdown('AE_Q2_HCP', AE_Q2_HCP,'stage1')}</td>
                        <td>{dropdown('AE_Q2_HCP_QualityMonitorQC', AE_Q2_HCP_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('AE_Q2_HCP_Amgen', AE_Q2_HCP_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.AE_Q2_HCP_Error_Reconciliation}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Q3_IPRC}</b></td>
                        <td>{dropdown('AE_Q3_IPRC', AE_Q3_IPRC,'stage1')}</td>
                        <td>{dropdown('AE_Q3_IPRC_QualityMonitorQC', AE_Q3_IPRC_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('AE_Q3_IPRC_Amgen', AE_Q3_IPRC_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.AE_Q3_IPRC_Error_Reconciliation}</td>

                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.PCID_Q2_Missed}</b></td>
                        <td>{dropdown('PCID_Q2_Missed', PCID_Q2_Missed,'stage1')}</td>
                        <td>{dropdown('PCID_Q2_Missed_QualityMonitorQC', PCID_Q2_Missed_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('PCID_Q2_Missed_Amgen', PCID_Q2_Missed_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.PCID_Q2_Missed_Error_Reconciliation}</td>

                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.PC_Q1_IPRC_Match}</b></td>
                        <td>{dropdown('PC_Q1_IPRC_Match', PC_Q1_IPRC_Match,'stage1')}</td>
                        <td>{dropdown('PC_Q1_IPRC_Match_QualityMonitorQC', PC_Q1_IPRC_Match_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('PC_Q1_IPRC_Match_Amgen', PC_Q1_IPRC_Match_Amgen, 'amgen')}</td>
                        <td>{scoreDetails.PC_Q1_IPRC_Error_Reconciliation}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.bioconnect_Match_capturedIn_probing_decisionTree_originalQM}</b></td>
                        <td>{dropdown('bioconnect_Match_capturedIn_probing_decisionTree_originalQM', bioconnect_Match_capturedIn_probing_decisionTree_originalQM,'stage1')}</td>
                        <td>{dropdown('bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC', bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC,'stage2')}</td>
                        <td>{dropdown('bioconnect_Match_capturedIn_probing_decisionTree_amgenQC', bioconnect_Match_capturedIn_probing_decisionTree_amgenQC, 'amgen')}</td>
                        <td>{scoreDetails.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='score-card-total'>
                    <Row>
                      <Col sm={10}>Total Error Reconciliation (Amgen QC vs Quality Monitor QC) </Col>
                      <Col>{Total_Error_Reconciliation ? Total_Error_Reconciliation : 0}</Col>
                    </Row>
                    <Row>
                      <Col sm={10}>Quality Percentage of the INT </Col>
                      <Col>{Quality_Percentage_INT ? Quality_Percentage_INT : 0}%</Col>
                    </Row>
                  </div>
                </div>
                <div className="layout-2">
                  <Row>
                    <Col md={12} sm={12}><label className="mb-2">Comments</label>

                      {/* {this.props.scoreCard==='new'? */}
                      <Form.Control as="textarea" name='newcomment' value={this.state.newcomment} onChange={this.handleChangeYesNo} />
                      {/* :<div className='scroll'><span>{comments}</span></div>} */}
                      {Array.isArray(this.state.comment) && commentHist.length > 3 &&<span onClick={this.showHistory} className="showCmt">Show Comment History</span> }
                      

                    </Col>
                  </Row>
                </div>
              </div>
              {/* <div className="row"><div className="col-xs-12 col-md-12"> 
      <button className="col-md-1 offset-11 btn-gobackTo mt-4 mb-4 pull-right" onClick={this.goBackTo}>Back</button></div>
    </div> */}
              <div className="row pull-right"><div className="col-xs-12 col-md-12">
                {/* <Button type="submit" onClick={this.showFollowUp} className='mt-4 button-color follow-up-back'>Follow Up</Button> */}
                {backButton}{routetoQM}{UpdateButton}             
              </div>
              </div>
            </div>}
          <PopupModels showPopup={this.state.scorecardUpdateModel} iconFlag="success" message={this.props.scoreCard === 'new' ? MessageConstant.ADD_SCORECARD : MessageConstant.UPDATE_SCORECARD} handleClosePopup={()=>this.handleUpdateClose(event)} />
          <PopupModels showPopup={this.state.scorecardErrorModel} iconFlag="error" message={MessageConstant.SCORECARD_ERROR} handleClosePopup={this.handleErrorClose.bind(this)} />
          <PopupModels showPopup={this.state.addScorecardFail} iconFlag="error" message={MessageConstant.S_ALREADY_CREATED} handleClosePopup={this.handleErrorClose.bind(this)} />
          <PopupModels showPopup={this.state.scorecardUserError} iconFlag="error" message={MessageConstant.S_USER_NOT_EXIST} handleClosePopup={this.handleErrorClose.bind(this)} />
        
          <Modal
        size="lg"
        show={this.state.showComment}
        onHide={this.hideHistory}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Comment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollcommentpopup">
          <CommentHistory commentHist={commentHist} />
        </Modal.Body>
      </Modal>

        </div>
      </div>
    )
  }
}
EditScoreCard.propTypes = {
  details: PropTypes.array,
  scoreCard: PropTypes.string,
  hideDetails: PropTypes.func,
  hideEditScoreCard: PropTypes.func

}
export default EditScoreCard;