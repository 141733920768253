import React, { Component } from 'react';
import './GimsReportChartLayout.css';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
import { GimsErrorCriticalityCharts, GimsErrorTypeCriticalityCharts, GimsSubcaseCountOwnerTeam, GimsErrorTypeSubcaseCountOwnerTeam, GimsSubCaseCountByType, GimsIntakeChannelCount, GimsAgentNotes, GimsMLDetails } from '../GimsReportChartLayout/GimsReportCharts/GimsReportCharts';
import GimsReportFilter from '../GimsReportFilter/GimsReportFilter';
// import { object } from 'prop-types';
import PropTypes from 'prop-types';

/*
 * A class to set the layouting the component 
 * calling the component and data to show the charts
 */
class GimsReportChartLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showChart: false,
            gimsModelReports: {}
        }
        this.getGimsReport = this.getGimsReport.bind(this);
        this.generateErrorTypeText = this.generateErrorTypeText.bind(this);
    }

    /*
    * Hook to call the api to pass the data 
    * for d3 to generate the charts
    */
    componentDidMount() {
        this.getGimsReport(null);
        //this.generateCanvas();
    }

    /*
    * Function to call the api which
    * called by componentDidMount() hook
    */
    getGimsReport(filteredData) {
        //console.log("filtered data", filteredData);
        //let data = filteredData ? (filteredData.length > 0 ? { datasetId: filteredData } : {}) : {};
        let data = filteredData ? filteredData : {};
        data["report"] = true;
        let self = this;
        utils.makeRequest(this, EndpointConstant.GIMS_REPORT_FROM_FILE, data, function (result) {
            if (result.error === 'false' || result.error === false) {
                const gimsModelReports = result;
                //console.log("results ", result);
                // let errors = [];
                self.props.setDataForReportGeneration(data);
                self.loading = false;
                self.setState({ gimsModelReports: gimsModelReports, showChart: true });
            }

        }
        )
    }

    /*
    * Check the api data, if undefined or null
    * @return 0
    * else @return data 
    */
    checkUndefinedOrNull(data) {
        if (data === undefined || data === null) {
            return 0;
        }
        return data;
    }

    /*
    * Generate ErrorTypes text based on
    * siteInfo condition
    */
    generateErrorTypeText(data) {
        const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
        if (siteInfo.hasOwnProperty('ErrorTypes')) {
            let ErrorTypes = siteInfo.ErrorTypes;
            return ErrorTypes[data];
        }
        return data;
    }

    getDatasetList = (datasetlist) => {
        console.log('datasetlist',datasetlist)
        this.props.getDatasetList(datasetlist)
    }

    /*
    * Rendering the UI view
    */
    render() {
        // console.log("this.props", this.props.state);
        const { showChart, gimsModelReports } = this.state;
        const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
        //console.log("siteInfo", siteInfo);
        //console.log("current state", this.state);
        /*
        * Error Criticality Start
        */
        let errorCriticalityProps = siteInfo.ErrorCriticality;
        errorCriticalityProps.noError = "No Error";
        const errorCriticality = [];
        if (gimsModelReports.hasOwnProperty('errorCriticality')) {
            let gimsErrorData = gimsModelReports.errorCriticality;
            let keys = Object.keys(errorCriticalityProps);
            let color = ['#6ED0E0', '#EAB839', '#EF843C'];
            keys.forEach((data, i) => {
                 errorCriticality.push({ errorTitle: errorCriticalityProps[data], count: this.checkUndefinedOrNull(gimsErrorData[data]), color: color[i] })
            });
        }
        /*
        * Error Criticality End
        */


        /*
        * Error Type with Business Criticality Start
        */
        const errorTypeCriticality = [];
        // let dummyErrorType = {
        //     "errorType": {
        //         "BusinessCritical": { "IncorrectDocumentation": 2, "SRMissing": 3, "InaappropiriatelyIdentifiedSR": 2, "PCMissing": 1, "MissingAttachment": 2, "MissingDocumentation": 3 },
        //         "BusinessNonCritical": { "IncorrectDocumentation": 2, "SRMissing": 3, "InaappropiriatelyIdentifiedSR": 2, "PCMissing": 1, "MissingAttachment": 2, "MissingDocumentation": 3 }
        //     }
        // };
        if (gimsModelReports.hasOwnProperty('errorType')) {
            //console.log("gimsModelReports", gimsModelReports.errorType);
            let gimsErrorTypeCriticalityData = gimsModelReports.errorType;
            let keyError = Object.keys(gimsModelReports.errorType);
            let color = ['#6ED0E0', '#AA8787', '#BFA4E2', '#6DB2A9', '#7EB26D', '#EAB839', '#EF843C', '#E24D42',
                '#6ED0E0', '#AA8787', '#BFA4E2', '#6DB2A9', '#7EB26D', '#EAB839', '#EF843C', '#E24D42',
                '#6ED0E0', '#AA8787', '#BFA4E2', '#6DB2A9', '#7EB26D', '#EAB839', '#EF843C', '#E24D42'];
            //console.log("keyError", keyError);
            keyError.forEach((data) => {
                //console.log("mappsdsf", gimsErrorTypeCriticalityData[data]);
                let reportBCBNC = Object.keys(gimsErrorTypeCriticalityData[data]);
                return reportBCBNC.forEach((rdata, j) => {
                    if (data === "BusinessCritical") {
                        errorTypeCriticality.push({ errorTitle: 'BC - ' + this.generateErrorTypeText(rdata), count: gimsErrorTypeCriticalityData[data][rdata], color: color[j] });
                    }
                    else if (data === "BusinessNonCritical") {
                        errorTypeCriticality.push({ errorTitle: 'BNC - ' + this.generateErrorTypeText(rdata), count: gimsErrorTypeCriticalityData[data][rdata], color: color[j] })
                    }
                    // return errorCriticality;
                })
            });
            //console.log("errorTypeCriticality1errorTypeCriticality1", errorTypeCriticality);
        }

        /*
        * Subcase count by Owner Start
        */
        const subCaseCountByOwner = [];
        if (gimsModelReports.hasOwnProperty('subcaseCountByOwnerTeam')) {
            let gimssubcaseCountOwner = gimsModelReports.subcaseCountByOwnerTeam;
            let subcaseOwnerKey = Object.keys(gimssubcaseCountOwner);
            let color = ['#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D'];
            subcaseOwnerKey.forEach((data, i) => {
                 subCaseCountByOwner.push({ errorTitle: data, count: gimssubcaseCountOwner[data], color: color[i] });
            })
            //console.log("subCaseCountByOwner", subCaseCountByOwner);
        }
        /*
        * Subcase count by Owner Ends
        */

        var errorTypesubCaseCountByOwner1 = [];
        // let gimsModelDummy = {
        //     "errorTypeCountBysubcaseOwner": {
        //         "SRMissing": { "TeamUS": 2, "TeamUK": 3 },
        //         "InaappropiriatelyIdentifiedSR": { "TeamUS": 1 },
        //         "PCMissing": { "TeamUS": 2 },
        //         "MissingAttachment": { "TeamUS": 3 },
        //         "IncorrectDocumentation": { "TeamUS": 2 },
        //         "MissingDocumentation": { "TeamUS": 1 }
        //     }
        // }
        if (gimsModelReports.hasOwnProperty('errorTypeCountBysubcaseOwner')) {
            let gimserrorTypesubCaseCountByOwner = gimsModelReports.errorTypeCountBysubcaseOwner;
            let errorTypeSubCaseKey = Object.keys(gimserrorTypesubCaseCountByOwner);
            let color = ['#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D',
                '#6ED0E0', '#7EB26D', '#EAB839', '#EF843C', '#EF843C', '#E24D42', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D'];
            //console.log("gimserrorTypesubCaseCountByOwner", gimserrorTypesubCaseCountByOwner);
            //console.log("errorTypesubcasekey", errorTypeSubCaseKey);
            if (errorTypeSubCaseKey) {
                errorTypesubCaseCountByOwner1 = errorTypeSubCaseKey.map((key, index) => {
                    //console.log("sdfsdfsd", key);
                    let teamKey = Object.keys(gimserrorTypesubCaseCountByOwner[key]);
                    //console.log("teamKeysss", teamKey);
                    let teamData = [];
                    teamKey.forEach((team) => {
                         teamData.push({ errorTitle: team, count: gimserrorTypesubCaseCountByOwner[key][team], color: color[index] })
                    });
                    //console.log("teamData", teamData);
                    return (
                        <div className="col-md-6" key={key}>
                            <h6 className="mt-3 sub-head-report">{key}</h6>
                            <GimsErrorTypeSubcaseCountOwnerTeam
                                id={key.replace(/\s/g, '')}
                                data={teamData}
                                width={450}
                                height={300}
                                dataReports={this.state.gimsModelReports}
                            />
                        </div>
                    )
                })
            }
        }

        /*
        * Subcase count by type Start
        */
        const subCaseCountByType = [];
        if (gimsModelReports.hasOwnProperty('subcaseCountByType')) {
            let gimssubcaseType = gimsModelReports.subcaseCountByType;
            let subcasetypeKey = Object.keys(gimssubcaseType);
            let color = ['#EAB839', '#EF843C', '#E24D42', '#EAB839', '#EF843C', '#E24D42'];
            subcasetypeKey.forEach((data, i) => {
                 subCaseCountByType.push({ errorTitle: data, count: gimssubcaseType[data], color: color[i] });
            })
            //console.log("subCaseCountByType1", subCaseCountByType);
        }
        /*
         * Subcase count by type End
         */

        /*
        * Intake Channel count Start
        */
        const intakeChannelCount = [];
        if (gimsModelReports.hasOwnProperty('channelCount')) {
            let gimsChannelData = gimsModelReports.channelCount;
            let channelKeys = Object.keys(gimsChannelData);
            let color = ['#7EB26D', '#EAB839', '#EF843C', '#E24D42', '#6ED0E0', '#987EAA', '#AA8787', '#6DB2A9', '#BFA4E2', '#7EB26D']
            channelKeys.forEach((data, i) => {
                 intakeChannelCount.push({ errorTitle: data ? data : 'Unknown', count: gimsChannelData[data], color: color[i] })
            });
            //console.log("channelKeys", intakeChannelCount);
        }
        /*
        * Intake Channel count End
        */

        /*
        * AgentNotes empty count and all with mlcompleted and manual count Start
        */
       const agentNotes = [];
       if (gimsModelReports.hasOwnProperty('agentNotesCount')) {
           let gimsAgentnotesData = gimsModelReports.agentNotesCount;
           let agentnotesKeys = Object.keys(gimsAgentnotesData);
           let color = ['#6ED0E0', '#987EAA','#7EB26D', '#EAB839'];
           agentnotesKeys.forEach((data, i) => {
                agentNotes.push({ errorTitle: getTitle(data), count: gimsAgentnotesData[data], color: color[i] })
           });
           //console.log("agentnotesKeys", agentNotes);
       }
       /*
       * AgentNotes empty count and all with mlcompleted and manual count End
       */
      
        /*
        * AE, PC, OSF count Start
        */
       const predictionCount = [];
       if (gimsModelReports.hasOwnProperty('predictionCount')) {
           let gimsMlDetailsData = gimsModelReports.predictionCount;
           let mldetailsKeys = Object.keys(gimsMlDetailsData);
           let color = ['#EF843C', '#EAB839', '#E24D42'];
           mldetailsKeys.forEach((data, i) => {
                predictionCount.push({ errorTitle: getTitle(data), count: gimsMlDetailsData[data], color: color[i] })
           });
           //console.log("mldetailsKeys", predictionCount);
       }
       /*
       * AE, PC, OSF count End
       */
      function getTitle(title) {
        let data = '';
        if(title=='emptyAgentNotesCount'){
            data = 'Cases without AgentNotes';
        }
        if(title == 'agentNotesCount'){
            data = 'Cases with AgentNotes';
        }
        if(title == 'mlCompletedCount'){
            data = 'ML Completed Count';
        }
        if(title == 'manualCount'){
            data = 'Manual Count';
        }
        if(title == 'ae'){
            data = 'AE';
        }
        if(title == 'pc'){
            data = 'PC';
        }
        if(title == 'osf'){
            data = 'OSF';
        }
        return data;
   }
        return (
            <div className="gims-charts">
                <div className="row">
                    <div className="col-md-12">
                        <GimsReportFilter getGimsFilteredReport={this.getGimsReport} getDatasetList={(datasetlist) => {
                            this.getDatasetList(datasetlist)}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 chart-pad-right">
                        {this.props.showChecked && <span className="selectCheckbox"><input type="checkbox" name="sectionOne" checked={this.props.sectionOne} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionOne">
                            <h6 className="mt-3">Cases by Error Criticality</h6>
                            {
                                showChart &&
                                <GimsErrorCriticalityCharts
                                    id="errorcriticality"
                                    data={errorCriticality}
                                    width={450}
                                    height={300}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 chart-pad-left pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionTwo" checked={this.props.sectionTwo} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionTwo">
                            <h6 className="mt-3">Cases by Error Type</h6>
                            {
                                showChart &&
                                <GimsErrorTypeCriticalityCharts
                                    id="errorTypeBusinesscriticality"
                                    data={errorTypeCriticality}
                                    width={450}
                                    height={300}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionThree" checked={this.props.sectionThree} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionThree">
                            <h6 className="mt-3">Sub-cases count by owner team</h6>
                            {
                                showChart &&
                                <GimsSubcaseCountOwnerTeam
                                    id="subCaseCountByOwner"
                                    data={subCaseCountByOwner}
                                    width={1100}
                                    height={300}
                                    dataReports={this.state.gimsModelReports}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionFour" checked={this.props.sectionFour} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionFour">
                            <h6 className="mt-3">Error type counts by sub-case Owner Team</h6>
                            <div className="row">
                                {errorTypesubCaseCountByOwner1}
                                {/*  <div className="col-md-6">
                                    <h6 className="mt-3 sub-head-report">BNC Inappropriately Identified AE </h6>
                                    <GimsErrorTypeSubcaseCountOwnerTeam
                                        id="bncInappropriate"
                                        data={errorTypesubCaseCountByOwner}
                                        width={400}
                                        height={200}
                                        dataReports={this.state.gimsModelReports}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 chart-pad-right">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionFive" checked={this.props.sectionFive} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionFive">
                            <h6 className="mt-3">Sub-case count by type</h6>
                            {
                                showChart &&
                                <GimsSubCaseCountByType
                                    id="subcaseCount"
                                    data={subCaseCountByType}
                                    width={450}
                                    height={380}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 chart-pad-left pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionSix" checked={this.props.sectionSix} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionSix">
                            <h6 className="mt-3">Intake Channel count</h6>
                            {
                                showChart &&
                                <GimsIntakeChannelCount
                                    id="channelCount"
                                    data={intakeChannelCount}
                                    width={450}
                                    height={300}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 chart-pad-left pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionSeven" checked={this.props.sectionSeven} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionSeven">
                            <h6 className="mt-3">Agent Notes and Difference in classification</h6>
                            {
                                showChart &&
                                <GimsAgentNotes
                                    id="agentnotes"
                                    data={agentNotes}
                                    width={450}
                                    height={300}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 chart-pad-left pad-adjust">
                        {this.props.showChecked && <span className="selectCheckbox">
                            <input type="checkbox" name="sectionEight" checked={this.props.sectionEight} onChange={this.props.handleChange} /></span>}
                        <div className="col-md-12 chart-outline" id="sectionEight">
                            <h6 className="mt-3">AE, PC, OSF Prediction</h6>
                            {
                                showChart &&
                                <GimsMLDetails
                                    id="classification"
                                    data={predictionCount}
                                    width={450}
                                    height={300}
                                    dataReports={this.state.gimsModelReports} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/*
 * GimsReportChartLayout propTypes checking
 * for checking props type
 */
GimsReportChartLayout.propTypes = {
    showChecked: PropTypes.bool,
    sectionSix: PropTypes.bool,
    sectionFive: PropTypes.bool,
    sectionFour: PropTypes.bool,
    handleChange: PropTypes.func,
    sectionThree: PropTypes.bool,
    sectionTwo: PropTypes.bool,
    sectionOne: PropTypes.bool,
    sectionSeven: PropTypes.bool,
    sectionEight: PropTypes.bool
}

export default GimsReportChartLayout;