var config = {};
config.env = 'dev';

//AWS
config.aws = {};
config.aws.apiRoot = "https://dev-api-gims.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'MsXF23B2Jk3AAn6iW0QAE2TbNmNma2NYK3zbc67e';

export default config;
